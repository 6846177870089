import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { useState,useEffect } from 'react/cjs/react.development';
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSigninMutation } from "../../store/slices/apis/authApi";
import { setAuthenticated, setUser } from "../../store/slices/authSlice";
import Input from "../../Components/Utils/Input";
import { toast } from "react-toastify";
// import messageSlice from "../../../Redux/MessageSlice";
// import messageSlice from "../../../Redux/MessageSlice";

export default function StudentLogin() {
  const [loading, setLoading] = useState(false);
  // const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const [signin] = useSigninMutation();
  // const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log(message);
  // useEffect(() => {
  //   dispatch(messageSlice.actions.clearMessage());
  //   if (isLoggedIn) {
  //     console.log("sd", isLoggedIn);
  //     navigate("/dashboard");
  //     window.location.reload();
  //   }
  // }, []);
  const initialValues = {
    username: "",
    password: "",
    // panel: "student",
  };
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      // .email("Please enter a valid email")
      .required("This field is required!"),
    password: Yup.string().required("This field is required!"),
  });

  const handleLogin = async (formValue, { resetForm }) => {
    setLoading(true);
    try {
      const result = await signin(formValue).unwrap();
      if (result.status) {
        toast.success("Login Successful");
        dispatch(setAuthenticated(true));
        dispatch(setUser(result.user || {}));
        navigate("/dashboard");
        window.location.reload();
      } else {
        toast.error(result.message);
      }
    } catch (err) {
      toast.error(err?.data?.message ?? "");
      console.log("error ", err);
    } finally {
      // resetForm();
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     navigate("/dashboard", { replace: true });
  //   }
  // }, [isLoggedIn, navigate]);

  return (
    <>
      <div className='flex items-center justify-center min-h-full px-4 mt-10 sm:px-6 lg:px-8'>
        <div className='w-full max-w-xl p-10 space-y-8 rounded-lg shadow-2xl'>
          <div>
            <img
              className='w-auto h-12 mx-auto rounded-full'
              src='https://erp.dibru.work/images/dibru.png'
              alt='Logo'
            />

            <h2 className='mt-6 text-lg font-extrabold text-center text-gray-600'>
              Dibrugarh University Certificate Portal
            </h2>
            <h2 className='mt-1 font-medium text-center text-gray-600 text-md'>
              Login to continue...
            </h2>
          </div>
          {/* {message && <Alert message={message} />} */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            <Form>
              <input type='hidden' name='remember' defaultValue='true' />
              <Input
                name='username'
                label='Registered Mobile No.'
                type='text'
                placeholder='Enter your registered email/contact number'
              />

              <Input
                name='password'
                label='Password'
                type='password'
                placeholder='Enter your password'
              />

              <div className='flex items-center justify-between my-10'>
                <div className='flex items-center'>
                  <input
                    id='remember-me'
                    name='remember-me'
                    type='checkbox'
                    className='w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500'
                  />
                  <label
                    htmlFor='remember-me'
                    className='block ml-2 text-sm text-gray-900'
                  >
                    Remember me
                  </label>
                </div>

                <div className='text-sm'>
                  <Link
                    to='/forgot-password'
                    className='font-medium text-indigo-600 hover:text-indigo-500'
                  >
                    Forgot Password?
                  </Link>
                </div>
              </div>

              <div>
                <button
                  type='submit'
                  className='relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  disabled={loading}
                >
                  {loading && (
                    <span className='spinner-border spinner-border-sm'></span>
                  )}
                  <span>Login</span>
                </button>

                <div className='mt-5'>
                  <span>Not registered? </span>
                  <Link to='/register' className='text-indigo-600'>
                    Create New Account
                  </Link>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
}
