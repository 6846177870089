import { Fragment, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLogoutMutation } from "../../store/slices/apis/authApi";
import { apiSlice } from "../../store/slices/apis/apiSlice";
import { toast } from "react-toastify";
import { RxHamburgerMenu } from "react-icons/rx";
import { ImUser } from "react-icons/im";
import Sidebar from "./Sidebar";
import { setOpenSidebar } from "../../store/slices/utilSlice";
import { setAuthenticated, setUser } from "../../store/slices/authSlice";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Header() {
  const [isLoading, setLoading] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();
  const authData = useSelector((state) => state.auth);
  console.log(authData);
  const openSidebar = useSelector((state) => state.util.openSidebar);
  const handleLogout = async () => {
    setLoading(true);
    try {
      const result = await logout().unwrap();
      if (result.status) {
        dispatch(setAuthenticated(false));
        // dispatch(logout());

        dispatch(apiSlice.util.resetApiState());
        navigate("/");
        window.location.reload();
      } else {
        toast("Something went wrong");
      }
    } catch (err) {
      console.log(err);
    } finally {
      navigate("/");
      window.location.reload();
    }
  };
  return (
    <>
      {openSidebar && (
        <Sidebar
          open={openSidebar}
          onClose={() => {
            dispatch(setOpenSidebar(false));
          }}
        />
      )}
      <header className='header'>
        {/* w-full mx-auto px-2 sm:px-6 lg:px-8 fixed bg-gradient-to-r to-indigo-800 from-blue-500 shadow-md */}
        <div className='px-2 mx-auto shadow-md sm:px-6 lg:px-8 bg-gradient-to-r to-indigo-800 from-blue-500'>
          <div className='flex items-center justify-between h-16'>
            <div className='flex items-center flex-1 sm:items-stretch sm:justify-start'>
              <div className='flex items-center flex-shrink-0'>
                <RxHamburgerMenu
                  style={{
                    width: 25,
                    height: 25,
                    marginRight: 10,
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={() => dispatch(setOpenSidebar(true))}
                />
                <img
                  src='https://erp.dibru.work/images/dibru.png'
                  className='mr-3 logo'
                  alt='Avatar'
                />
                <span className='font-bold text-white capitalize'>
                  {authData.user?.user?.name}
                </span>
              </div>
              <div className='flex items-center flex-shrink-0 font-bold text-white capitalize ml-72'>
                Dibrugarh University Certificate Portal
              </div>
            </div>

            <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
              <button
                type='button'
                className='p-1 text-gray-400 bg-gray-800 rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'
              >
                <span className='sr-only'>View notifications</span>
                <BellIcon className='w-6 h-6' aria-hidden='true' />
              </button>

              {/* Profile dropdown */}
              <Menu as='div' className='relative ml-3'>
                <div>
                  <Menu.Button className='flex text-sm bg-gray-800 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'>
                    <span className='sr-only'>Open user menu</span>
                    {/* <img
                        className="w-8 h-8 rounded-full"
                        src="https://erp.dibru.work/images/dibru.png"
                        alt=""
                      /> */}
                    <ImUser className='usericon' />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='absolute right-0 z-10 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to='/change-password'
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          Change Password
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to='/'
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                          onClick={handleLogout}
                        >
                          Sign out
                        </Link>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
// // console.log(authData);
// if (!authData.isLoggedIn) {
//   // console.log("aaaaa");

//   window.location.pathname("/");
// }

// const logOut = useCallback(() => {
// console.log("logout call");

//   dispatch(logout())
//     .unwrap()
//     .then(() => {
//       navigate("/");
//     });
// }, [dispatch]);

// useEffect(() => {
//   EventBus.on("logout", () => {
//     logOut();
//   });

// return () => {
//     EventBus.remove("logout");
//   };
// }, [authData.user, logOut]);

// const [state, setState] = useContext(userContext)

// console.log(authData);
// if (!authData.isLoggedIn) {
//   return <Navigate to="/student-login" />;
// }
