import React from "react";
import { useGetSingleApplicationQuery } from "../../store/slices/apis/applicationApi";
import { useParams } from "react-router-dom";
import CertificateReviewPayment from "./CertificateReviewPayment";
import MigrationCertificateReviewPayment from "./Migration/MigrationCertificateReviewPayment";
import PreDegreeCertificateReviewPayment from "./PreDegree/PreDegreeCertificateReviewPayment";
import ProvisionalCertificateReviewPayment from "./Provisional/ProvisionalCertificateReviewPayment";
import TranscriptCertificateReviewPayment from "./Transcript/TranscriptCertificateReviewPayment";
import DuplicateCertificateReviewPayment from "./DuplicateCertificate/DuplicateCertificateReviewPayment";
import DuplicateProvisionalCertificateReviewPayment from "./DuplicateProvisional/DuplicateProvisionalCertificateReviewPayment";
import DuplicateMigrationCertificateReviewPayment from "./DuplicateMigration/DuplicateMigrationCertificateReviewPayment";
import SurnameChangeCertificateReviewPayment from "./SurnameChange/SurnameChangeCertificateReviewPayment";
import RegistrationCertificateReviewPayment from "./Registration/RegistrationCertificateReviewPayment";
import DuplicateRegistrationCertificateReviewPayment from "./DuplicateRegistration/DuplicateRegistrationCertificateReviewPayment";
import NameCorrectionRegistrationCertificateReviewPayment from "./NameCorrectionRegistration/NameCorrectionRegistrationCertificateReviewPayment";

export const ApplicationDetail = () => {
  const { application_id } = useParams();

  const {
    data: application,
    isLoading: isLoadingGetApplication,
    isSuccess: isSuccessGetApplication,
    isError: isErrorGetApplication,
  } = useGetSingleApplicationQuery(application_id);

  const certificate_id = application?.certificate_id;
  return (
    <>
      {/* origial certificate */}
      {certificate_id == 1 && (
        <CertificateReviewPayment
          data={application}
          certificateId={certificate_id}
        />
      )}
      {/* migration certificate */}
      {certificate_id == 2 && (
        <MigrationCertificateReviewPayment
          data={application}
          certificateId={certificate_id}
        />
      )}
      {/* pre degree certificate */}
      {certificate_id == 3 && (
        <PreDegreeCertificateReviewPayment
          data={application}
          certificateId={certificate_id}
        />
      )}
      {/* Provisional certificate */}
      {certificate_id == 4 && (
        <ProvisionalCertificateReviewPayment
          data={application}
          certificateId={certificate_id}
        />
      )}

      {/* Transcript */}
      {certificate_id == 5 && (
        <TranscriptCertificateReviewPayment
          data={application}
          certificateId={certificate_id}
        />
      )}
      {/* Duplicate */}
      {certificate_id == 6 && (
        <DuplicateCertificateReviewPayment
          data={application}
          certificateId={certificate_id}
        />
      )}
      {/* Duplicate provisional */}
      {certificate_id == 7 && (
        <DuplicateProvisionalCertificateReviewPayment
          data={application}
          certificateId={certificate_id}
        />
      )}
      {/* Duplicate migration */}
      {certificate_id == 8 && (
        <DuplicateMigrationCertificateReviewPayment
          data={application}
          certificateId={certificate_id}
        />
      )}
      {/* Surname Change */}
      {certificate_id == 9 && (
        <SurnameChangeCertificateReviewPayment
          data={application}
          certificateId={certificate_id}
        />
      )}
      {/* Registration  */}
      {certificate_id == 10 && (
        <RegistrationCertificateReviewPayment
          data={application}
          certificateId={certificate_id}
        />
      )}
      {/* Duplicate Registration Change */}
      {certificate_id == 11 && (
        <DuplicateRegistrationCertificateReviewPayment
          data={application}
          certificateId={certificate_id}
        />
      )}
      {/* name correction registration Change */}
      {certificate_id == 12 && (
        <NameCorrectionRegistrationCertificateReviewPayment
          data={application}
          certificateId={certificate_id}
        />
      )}
    </>
  );
};

export default ApplicationDetail;
