import React, { useEffect, useRef, useState } from "react";
import { CertificateCreateStep1 } from "./CertificateCreateStep1";
import { CertificateCreateStep2 } from "./CertificateCreateStep2";
import { CertificateDocUpload } from "./CertificateDocUpload";
import { CertificateReviewPayment } from "./CertificateReviewPayment";
import { AiOutlineUser } from "react-icons/ai";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { BsCloudUpload } from "react-icons/bs";
import { VscSmiley } from "react-icons/vsc";
import "./CertificateCreate.css";
import {
  useGetApplicationQuery,
  useGetPendingApplicationQuery,
} from "../../store/slices/apis/applicationApi";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import FeeStructure from "../FeeStructure";
import MigrationCertificateCreateStep1 from "./Migration/MigrationCertificateCreateStep1";
import MigrationCertificateCreateStep2 from "./Migration/MigrationCertificateCreateStep2";
import MigrationCertificateReviewPayment from "./Migration/MigrationCertificateReviewPayment";
import PreDegreeCertificateCreateStep1 from "./PreDegree/PreDegreeCertificateCreateStep1";
import PreDegreeCertificateCreateStep2 from "./PreDegree/PreDegreeCertificateCreateStep2";
import PreDegreeCertificateReviewPayment from "./PreDegree/PreDegreeCertificateReviewPayment";
import ProvisionalCertificateCreateStep1 from "./Provisional/ProvisionalCertificateCreateStep1";
import ProvisionalCertificateCreateStep2 from "./Provisional/ProvisionalCertificateCreateStep2";
import ProvisionalCertificateReviewPayment from "./Provisional/ProvisionalCertificateReviewPayment";
import TranscriptCertificateReviewPayment from "./Transcript/TranscriptCertificateReviewPayment";
import TranscriptCertificateCreateStep2 from "./Transcript/TranscriptCertificateCreateStep2";
import TranscriptCertificateCreateStep1 from "./Transcript/TranscriptCertificateCreateStep1";
import DuplicateCertificateCreateStep1 from "./DuplicateCertificate/DuplicateCertificateCreateStep1";
import DuplicateCertificateCreateStep2 from "./DuplicateCertificate/DuplicateCertificateCreateStep2";
import DuplicateCertificateReviewPayment from "./DuplicateCertificate/DuplicateCertificateReviewPayment";
import DuplicateProvisionalCertificateReviewPayment from "./DuplicateProvisional/DuplicateProvisionalCertificateReviewPayment";
import DuplicateProvisionalCertificateCreateStep2 from "./DuplicateProvisional/DuplicateProvisionalCertificateCreateStep2";
import DuplicateProvisionalCertificateCreateStep1 from "./DuplicateProvisional/DuplicateProvisionalCertificateCreateStep1";
import DuplicateMigrationCertificateCreateStep1 from "./DuplicateMigration/DuplicateMigrationCertificateCreateStep1";
import DuplicateMigrationCertificateCreateStep2 from "./DuplicateMigration/DuplicateMigrationCertificateCreateStep2";
import DuplicateMigrationCertificateReviewPayment from "./DuplicateMigration/DuplicateMigrationCertificateReviewPayment";
import SurnameChangeCertificateReviewPayment from "./SurnameChange/SurnameChangeCertificateReviewPayment";
import SurnameChangeCertificateCreateStep2 from "./SurnameChange/SurnameChangeCertificateCreateStep2";
import SurnameChangeCertificateCreateStep1 from "./SurnameChange/SurnameChangeCertificateCreateStep1";
import RegistrationCertificateCreateStep1 from "./Registration/RegistrationCertificateCreateStep1";
import RegistrationCertificateCreateStep2 from "./Registration/RegistrationCertificateCreateStep2";
import RegistrationCertificateReviewPayment from "./Registration/RegistrationCertificateReviewPayment";
import DuplicateRegistrationCertificateCreateStep1 from "./DuplicateRegistration/DuplicateRegistrationCertificateCreateStep1";
import DuplicateRegistrationCertificateCreateStep2 from "./DuplicateRegistration/DuplicateRegistrationCertificateCreateStep2";
import DuplicateRegistrationCertificateReviewPayment from "./DuplicateRegistration/DuplicateRegistrationCertificateReviewPayment";
import NameCorrectionRegistrationCertificateReviewPayment from "./NameCorrectionRegistration/NameCorrectionRegistrationCertificateReviewPayment";
import NameCorrectionRegistrationCertificateCreateStep2 from "./NameCorrectionRegistration/NameCorrectionRegistrationCertificateCreateStep2";
import NameCorrectionRegistrationCertificateCreateStep1 from "./NameCorrectionRegistration/NameCorrectionRegistrationCertificateCreateStep1";

export default function CertificateCreate() {
  const [page, setPage] = useState(1);
  const [openFeesModal, setOpenFeesModal] = useState(true);
  const { certificate_id } = useParams();
  // const [applicationId, setApplicationId] = useState(null);
  const handleTabChange = (page) => {
    setPage(page);
  };

  const {
    data: application,
    isLoading: isLoadingGetApplication,
    isSuccess: isSuccessGetApplication,
    isError: isErrorGetApplication,
  } = useGetPendingApplicationQuery(certificate_id);
  const headerName = useSelector((state) => state?.util.headerName);
  useEffect(() => {
    if (isSuccessGetApplication) {
      // setApplicationId(application?.id);
      setPage(application?.step || 1);
    }
  }, [
    application,
    isLoadingGetApplication,
    isSuccessGetApplication,
    isErrorGetApplication,
  ]);
  const effect = "bg--500";
  useEffect(() => {
    console.log("certificate_id", certificate_id);
    setPage(1);
  }, [certificate_id]);

  return (
    <>
      {openFeesModal && !application && (
        <FeeStructure
          certificate_id={certificate_id}
          onCancel={() => {
            setOpenFeesModal(false);
          }}
        />
      )}
      <div className='min-h-screen bg-gray-100'>
        <div className='overflow-scroll bg-white rounded-md shadow-md'>
          <div className='flex items-center justify-between px-4 py-3 bg-gray-200'>
            <h2 className='text-lg font-medium text-gray-800'>
              Application For {headerName}
            </h2>
            <div className='flex items-center'>
              <span className='mr-1 text-sm font-medium text-gray-500'>
                Step {page} of 4
              </span>
              <div className='flex items-center justify-center w-6 h-6 text-white bg-green-500 rounded-full'>
                {page}
              </div>
            </div>
          </div>
          <div className='progress_bar'>
            <div
              className={`steps ${page === 1 ? effect : ""}`}
              // onClick={() => setPage(1)}
            >
              <AiOutlineUser className='icons' />
              <span className='title'>Step 1</span>
            </div>
            <div
              className='progress_line'
              style={{
                borderColor: page >= 2 ? "rgb(59 130 246)" : "transparent",
              }}
            />
            <div
              className={`steps ${page === 2 ? effect : ""}`}
              // onClick={() => setPage(2)}
            >
              <HiOutlineClipboardDocumentList className='icons' />
              <span className='title'>Step 2</span>
            </div>
            <div
              className='progress_line'
              style={{
                borderColor: page >= 3 ? "rgb(59 130 246)" : "transparent",
              }}
            />

            <div
              className={`steps ${page === 3 ? effect : ""}`}
              // onClick={() => setPage(3)}
            >
              {/* <span className="loader "></span> */}
              <BsCloudUpload className='icons' />
              <span className='title '>Documents Upload</span>
            </div>
            <div
              className='progress_line'
              style={{
                borderColor: page >= 4 ? "rgb(59 130 246)" : "transparent",
              }}
            ></div>
            <div
              className={`steps ${page === 4 ? effect : ""}`}
              // onClick={() => setPage(4)}
            >
              <VscSmiley className='icons' />
              <span className='title'>Review Payment</span>
            </div>
          </div>
          <div className='p-4'>
            {page === 1 && (
              <>
                {/* original certificate */}
                {certificate_id == 1 && (
                  <CertificateCreateStep1
                    data={application}
                    certificate_id={certificate_id}
                    setPage={setPage}
                    setTab={handleTabChange}
                  />
                )}
                {/* migration certificate */}
                {certificate_id == 2 && (
                  <MigrationCertificateCreateStep1
                    data={application}
                    certificate_id={certificate_id}
                    setPage={setPage}
                    setTab={handleTabChange}
                  />
                )}
                {/* pre degree certificate */}
                {certificate_id == 3 && (
                  <PreDegreeCertificateCreateStep1
                    data={application}
                    certificate_id={certificate_id}
                    setPage={setPage}
                    setTab={handleTabChange}
                  />
                )}

                {/* provisional certificate */}
                {certificate_id == 4 && (
                  <ProvisionalCertificateCreateStep1
                    data={application}
                    certificate_id={certificate_id}
                    setPage={setPage}
                    setTab={handleTabChange}
                  />
                )}
                {/* Transcript certificate */}
                {certificate_id == 5 && (
                  <TranscriptCertificateCreateStep1
                    data={application}
                    certificate_id={certificate_id}
                    setPage={setPage}
                    setTab={handleTabChange}
                  />
                )}
                {/* duplicate original certificate */}
                {certificate_id == 6 && (
                  <DuplicateCertificateCreateStep1
                    data={application}
                    certificate_id={certificate_id}
                    setPage={setPage}
                    setTab={handleTabChange}
                  />
                )}
                {/* duplicate provisional certificate */}
                {certificate_id == 7 && (
                  <DuplicateProvisionalCertificateCreateStep1
                    data={application}
                    certificate_id={certificate_id}
                    setPage={setPage}
                    setTab={handleTabChange}
                  />
                )}

                {/* duplicate migration certificate */}
                {certificate_id == 8 && (
                  <DuplicateMigrationCertificateCreateStep1
                    data={application}
                    certificate_id={certificate_id}
                    setPage={setPage}
                    setTab={handleTabChange}
                  />
                )}
                {/* SurnameChange */}
                {certificate_id == 9 && (
                  <SurnameChangeCertificateCreateStep1
                    data={application}
                    certificate_id={certificate_id}
                    setPage={setPage}
                    setTab={handleTabChange}
                  />
                )}
                {/* Regisrtation Card */}
                {certificate_id == 10 && (
                  <RegistrationCertificateCreateStep1
                    data={application}
                    certificate_id={certificate_id}
                    setPage={setPage}
                    setTab={handleTabChange}
                  />
                )}
                {/* Duplicate Regisrtation Card */}
                {certificate_id == 11 && (
                  <DuplicateRegistrationCertificateCreateStep1
                    data={application}
                    certificate_id={certificate_id}
                    setPage={setPage}
                    setTab={handleTabChange}
                  />
                )}
                {/* NameCorrection Regisrtation Card */}
                {certificate_id == 12 && (
                  <NameCorrectionRegistrationCertificateCreateStep1
                    data={application}
                    certificate_id={certificate_id}
                    setPage={setPage}
                    setTab={handleTabChange}
                  />
                )}
              </>
            )}
            {page === 2 && (
              <>
                {/* origital certificate */}
                {certificate_id == 1 && (
                  <CertificateCreateStep2
                    data={application}
                    setTab={handleTabChange}
                  />
                )}
                {/* migration certificate */}
                {certificate_id == 2 && (
                  <MigrationCertificateCreateStep2
                    data={application}
                    setTab={handleTabChange}
                  />
                )}
                {/* pre degree certificate */}
                {certificate_id == 3 && (
                  <PreDegreeCertificateCreateStep2
                    data={application}
                    setTab={handleTabChange}
                  />
                )}{" "}
                {/* Provisional certificate */}
                {certificate_id == 4 && (
                  <ProvisionalCertificateCreateStep2
                    data={application}
                    setTab={handleTabChange}
                  />
                )}
                {/* Transcript certificate */}
                {certificate_id == 5 && (
                  <TranscriptCertificateCreateStep2
                    data={application}
                    setTab={handleTabChange}
                  />
                )}
                {/* Duplicate certificate */}
                {certificate_id == 6 && (
                  <DuplicateCertificateCreateStep2
                    data={application}
                    setTab={handleTabChange}
                  />
                )}
                {/* Duplicate provisional certificate */}
                {certificate_id == 7 && (
                  <DuplicateProvisionalCertificateCreateStep2
                    data={application}
                    setTab={handleTabChange}
                  />
                )}
                {/* Duplicate migration certificate */}
                {certificate_id == 8 && (
                  <DuplicateMigrationCertificateCreateStep2
                    data={application}
                    setTab={handleTabChange}
                  />
                )}
                {/* SurnameChange */}
                {certificate_id == 9 && (
                  <SurnameChangeCertificateCreateStep2
                    data={application}
                    setTab={handleTabChange}
                  />
                )}
                {/* Registration */}
                {certificate_id == 10 && (
                  <RegistrationCertificateCreateStep2
                    data={application}
                    setTab={handleTabChange}
                  />
                )}{" "}
                {/* Duplicate Registration */}
                {certificate_id == 11 && (
                  <DuplicateRegistrationCertificateCreateStep2
                    data={application}
                    setTab={handleTabChange}
                  />
                )}
                {/* NameCorrection Registration */}
                {certificate_id == 12 && (
                  <NameCorrectionRegistrationCertificateCreateStep2
                    data={application}
                    setTab={handleTabChange}
                  />
                )}
              </>
            )}
            {page === 3 && (
              <CertificateDocUpload
                application={application}
                certificateId={certificate_id}
                setTab={handleTabChange}
              />
            )}
            {page === 4 && (
              <>
                {/* origial certificate */}
                {certificate_id == 1 && (
                  <CertificateReviewPayment
                    data={application}
                    certificateId={certificate_id}
                    setTab={handleTabChange}
                  />
                )}
                {/* migration certificate */}
                {certificate_id == 2 && (
                  <MigrationCertificateReviewPayment
                    data={application}
                    certificateId={certificate_id}
                    setTab={handleTabChange}
                  />
                )}
                {/* pre degree certificate */}
                {certificate_id == 3 && (
                  <PreDegreeCertificateReviewPayment
                    data={application}
                    certificateId={certificate_id}
                    setTab={handleTabChange}
                  />
                )}
                {/* Provisional certificate */}
                {certificate_id == 4 && (
                  <ProvisionalCertificateReviewPayment
                    data={application}
                    certificateId={certificate_id}
                    setTab={handleTabChange}
                  />
                )}

                {/* Transcript */}
                {certificate_id == 5 && (
                  <TranscriptCertificateReviewPayment
                    data={application}
                    certificateId={certificate_id}
                    setTab={handleTabChange}
                  />
                )}
                {/* Duplicate */}
                {certificate_id == 6 && (
                  <DuplicateCertificateReviewPayment
                    data={application}
                    certificateId={certificate_id}
                    setTab={handleTabChange}
                  />
                )}
                {/* Duplicate provisional */}
                {certificate_id == 7 && (
                  <DuplicateProvisionalCertificateReviewPayment
                    data={application}
                    certificateId={certificate_id}
                    setTab={handleTabChange}
                  />
                )}
                {/* Duplicate migration */}
                {certificate_id == 8 && (
                  <DuplicateMigrationCertificateReviewPayment
                    data={application}
                    certificateId={certificate_id}
                    setTab={handleTabChange}
                  />
                )}
                {/* Surname Change */}
                {certificate_id == 9 && (
                  <SurnameChangeCertificateReviewPayment
                    data={application}
                    certificateId={certificate_id}
                    setTab={handleTabChange}
                  />
                )}
                {/* Registration */}
                {certificate_id == 10 && (
                  <RegistrationCertificateReviewPayment
                    data={application}
                    certificateId={certificate_id}
                    setTab={handleTabChange}
                  />
                )}
                {/* DuplicateRegistration */}
                {certificate_id == 11 && (
                  <DuplicateRegistrationCertificateReviewPayment
                    data={application}
                    certificateId={certificate_id}
                    setTab={handleTabChange}
                  />
                )}

                {/* NameCorrectionRegistration */}
                {certificate_id == 12 && (
                  <NameCorrectionRegistrationCertificateReviewPayment
                    data={application}
                    certificateId={certificate_id}
                    setTab={handleTabChange}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
