import React, { useEffect, useRef, useState } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { GrClose } from "react-icons/gr";
import { GenIcon } from "react-icons";
const AssameseKeyboard = React.memo(
  ({ name, setFieldValue, onClose, onChange, value }) => {
    // console.log("AssameseKeyboard", name, "#### ", value[name]);
    const [layoutName, setLayoutName] = useState("default");
    const keyboard = useRef();
    const joinRules = {
      সক: "স্ক", // Join "স" and "ক" to "স্ক"
      কক: "ক্ক",
      কষ: "ক্ষ",
      জঞ: "জ্ঞ",
      তত: "ত্ত",
      তথ: "ত্থ",
      নত: "ন্ত",
      নদ: "ন্দ",
      ণট: "ণ্ট",
      ণঠ: "ণ্ঠ",
      ণড: "ণ্ড",
      ণঢ: "ণ্ঢ",
      সত: "স্ত",
      সথ: "স্থ",
      মন: "ম্ন",
      মপ: "ম্প",
      মফ: "ম্ফ",
      মব: "ম্ব",
      মভ: "ম্ভ",
      মম: "ম্ম",
      সপ: "স্প",
      সফ: "স্ফ",
      সন: "স্ন",
      সম: "স্ম",
      // হন: "হ্ণ",
      হন: "হ্ন",
      হম: "হ্ম",
      পট: "প্ট",
      পত: "প্ত",
      পন: "প্ন",
      পপ: "প্প",
      পল: "প্ল",
      পস: "প্স",
      ফর: "ফ্র",
      কট: "ক্ট",
      কত: "ক্ত",
      কর: "ক্র",
      গধ: "গ্ধ",
      গন: "গ্ন",
      গ্ব: "গব",
      গ্ম: "গম",
      গ্ল: "গল",
      ঘ্ন: "ঘন",
      ঙ্ক: "ঙক",
      ঙ্গ: "ঙগ",
      চ্ছ: "চছ",
      জ্জ: "জজ",
      জঝ: "জ্ঝ",
      জব: "জ্ব",
      জম: "জ্ম",
      ঞ্: "ঞ্",
      ঞছ: "ঞ্ছ",
      ঞজ: "ঞ্জ",
      তন: "ত্ন",
      তব: "ত্ব",
      তম: "ত্ম",
      থব: "থ্ব",
      দগ: "দ্গ",
      দদ: "দ্দ",
      দধ: "দ্ধ",
      দব: "দ্ব",
      দভ: "দ্ভ",
      দম: "দ্ম",
      দর: "দ্র",
      ধব: "ধ্ব",
      নট: "ন্ট",
      নঠ: "ন্ঠ",
      নড: "ন্ড",
      নত: "ন্ত",
      নদ: "ন্দ",
      বজ: "ব্জ",
      বদ: "ব্দ",
      বধ: "ব্ধ",
      বব: "ব্ব",
      বল: "ব্ল",
      ভর: "ভ্র",
      নধ: "ন্ধ",
      নন: "ন্ন",
      নব: "ন্ব",
      নম: "ন্ম",
      গধ: "গ্ধ",
    };
    useEffect(() => {
      if (value && value[name]) {
        keyboard.current.setInput(value[name] || "");
      }
    }, [value]);
    const handleInputChange = (newValue) => {
      // console.log("handleInputChange", name, newValue);
      setFieldValue(name, newValue);
    };

    const joinCharacters = (input) => {
      const lastTwoChars = input.slice(-2);
      if (lastTwoChars in joinRules) {
        return input.slice(0, -2) + joinRules[lastTwoChars];
      } else {
        return input;
      }
    };

    const onKeyPress = (button) => {
      console.log("onKeyPress called", button);
      let updatedInput = value[name];
      if (button === "{shift}" || button === "{lock}" || button === "{tab}") {
        setLayoutName(layoutName === "shift" ? "default" : "shift");
        return;
      } else if (button === "{য়ুক্তাক্ষৰ}") {
        setLayoutName("য়ুক্তাক্ষৰ");
        return;
      } else {
        setLayoutName(layoutName);
      }
      if (button === "{enter}") {
        updatedInput += "\n";
      } else if (button === "{bksp}") {
        let string = updatedInput.substring(0, updatedInput.length - 1);
        handleInputChange(string);
        return;
      } else if (button === "{space}") {
        updatedInput += " ";
      } else if (button === "{join}") {
        updatedInput = joinCharacters(updatedInput);
      } else if (button === "{ClearAll}") {
        handleInputChange("");
        return;
      } else {
        updatedInput += button;
      }
      handleInputChange(updatedInput);
    };

    return (
      <div className='simple-keybaord'>
        <div className='keyboard'>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 5,
            }}
          >
            <a
              target='_blank'
              href='https://certificate.dibru.work/How to Join on the Virtual Assamese Keyboard.pdf'
              rel='noreferrer'
              className='mr-5 text-blue-500'
            >
              Help?
            </a>
            <button>
              <GrClose
                style={{
                  width: 23,
                  height: 23,
                }}
                onClick={() => onClose()}
              />
            </button>
          </div>
          <Keyboard
            keyboardRef={(r) => (keyboard.current = r)}
            inputName={name}
            layoutName={layoutName}
            layout={{
              default: [
                "\u0965 \u09e7 \u09e8 \u09e9 \u09ea \u09eb \u09ec \u09ed \u09ee \u09ef \u09e6 \u002d \u09c3 {bksp}",
                "{tab} \u09cc \u09c8 \u09be \u09c0 \u09c2 \u09ac \u09b9 \u0997 \u09a6 \u099c \u09a1 \u09bc",
                "\u09cb \u09c7 \u09cd \u09bf \u09c1 \u09aa \u09f0 \u0995 \u09a4 \u099a \u099f \u09CE",
                "{shift} \u0982 \u09ae \u09a8 \u09f1 \u09b2 \u09b8 \u002c \u002e \u09df \u09DC \u09A2\u09BC",
                "ম্ম {য়ুক্তাক্ষৰ} {space} ् ্র ্য র্য {join} {ClearAll}",
              ],
              shift: [
                "\u0021 \u0040 ( ) \u0983 \u098b {bksp}",
                "{tab} \u0994 \u0990 \u0986 \u0998 \u098a \u09ad \u0999 \u0998 \u09a7 \u099d \u09a2 \u099e",
                "\u0993 \u098f \u0985 \u0987 \u0989 \u09ab \u0996 \u09a5 \u099b \u099b \u09a0 {enter}",
                "{shift} \u0981 \u09a3 \u0988 \u09b6 \u09b7 \u0964 \u09af \u2019",
                "{য়ুক্তাক্ষৰ} {space} {join}",
              ],
              য়ুক্তাক্ষৰ: [
                "ক্ক ক্ষ জ্ঞ ত্ত ত্থ ন্ত ন্দ ণ্ট ণ্ঠ ণ্ড ণ্ঢ স্ত স্থ ম্ন ম্প ম্ফ {bksp}",
                "{tab} স্প স্ফ স্ন স্ম হ্ণ হ্ন হ্ম স্ম প্ট প্ত প্ন প্প প্ল প্স ফ্র",
                "ক্ট ক্ত ক্ষ ক্র গ্ধ গ্ন গ্ব গ্ম গ্ল ঘ্ন ঙ্ক ঙ্গ চ্ছ জ্জ জ্ঝ জ্ঞ জ্ব",
                "ণ্ঢ ত্ত ত্ন ত্ব ত্ম থ্ব দ্গ দ্দ দ্ধ দ্ব দ্ভ দ্ম দ্র ধ্ব ন্ত",
                "ব্জ ব্দ ব্ধ ব্ব ব্ল ভ্র ন্ধ ন্ন ন্ব ন্ম ট্ট ট্ব ড্ড্ ণ্ঠ ণ্ থ্ব ঞ্চ ঙ্ক ক্ষ্ম",
                "ষ্ণ ক্ষ গ্ধ ক্ত ধ্র ঞ্ ঞ্ছ ঞ্জ ম্ভ ম্ম ক্ম ম্ব",
                "{space} {shift} {join}",
              ],
            }}
            display={{
              "{join}": "⟕ Join",
              "{enter}": "↵",
              "{bksp}": "⌫",
              "{shift}": "⇧",
              "{tab}": "⇥",
              "{lock}": "⇪",
              // স্ন: ["স", "্", "ন"],
            }}
            // onChange={handleInputChange}
            onKeyPress={(button) => onKeyPress(button)}
            physicalKeyboardHighlight={true}
          />
        </div>
      </div>
    );
  }
);

export default AssameseKeyboard;
