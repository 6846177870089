import React from "react";
import Drawer from "../Utils/Drawer";
import { useGetCertificatesQuery } from "../../store/slices/apis/masterApi";
import { Link } from "react-router-dom";
import SidebarMenu from "./SidebarMenu";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import { TbCertificate } from "react-icons/tb";
import { RiAccountPinBoxLine } from "react-icons/ri";
import { HiOutlineHome } from "react-icons/hi2";
import { useDispatch } from "react-redux";
import { setHeaderName } from "../../store/slices/utilSlice";

const Sidebar = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const {
    data: certificates = [],
    isLoading: isLoadingGetCertificates,
    isSuccess: isSuccessGetCertificates,
    isError: isErrorGetCertificates,
  } = useGetCertificatesQuery();
  return (
    <Drawer
      title='Dibrugrah University Certificate Portal'
      visible={true}
      placement='left'
      onClose={onClose}
      style={{}}
    >
      <div>
        <SidebarMenu
          key={1}
          to={`/dashboard`}
          icon={
            <HiOutlineHome
              className='w-4 h-4 mr-1 text-blue-600'
              aria-hidden='true'
            />
          }
          label='Home'
        />
        {/* {isSuccessGetCertificates &&
          certificates.map((certificate) => (
         
            <SidebarMenu
              onClick={() => {
                dispatch(setHeaderName(certificate.name));
              }}
              key={certificate.id}
              to={`/certificates/create/${certificate.id}`}
              icon={
                <TbCertificate
                  className='w-4 h-4 mr-1 text-blue-600'
                  aria-hidden='true'
                />
              }
              label={certificate.name}
            />
          ))} */}
        {/* <SidebarMenu
          key={1}
          to={`/dashboard/refund`}
          icon={
            <HiOutlineReceiptRefund
              className="w-4 h-4 mr-1 text-blue-600"
              aria-hidden="true"
            />
          }
          label="Apply for Refund"
        /> */}
        <SidebarMenu
          key={2}
          to={`my_account`}
          icon={
            <RiAccountPinBoxLine
              className='w-4 h-4 mr-1 text-blue-600'
              aria-hidden='true'
            />
          }
          label='My Account'
        />
      </div>
    </Drawer>
  );
};

export default Sidebar;
