import React from "react";
import { Link } from "react-router-dom";
import * as HIcons from "@heroicons/react/outline";
import { useDispatch } from "react-redux";
import { setOpenSidebar } from "../../store/slices/utilSlice";
function SidebarMenu(props) {
  // const TheIcon = icons[props.icon];
  const dispatch = useDispatch();

  return (
    <Link
      to={props.to}
      className='flex items-center h-12 px-2 py-4 overflow-hidden text-sm text-gray-700 transition duration-300 ease-in-out rounded text-ellipsis whitespace-nowrap hover:text-blue-600 hover:bg-blue-300'
      href='#!'
      data-mdb-ripple='true'
      data-mdb-ripple-color='primary'
      onClick={() => {
        dispatch(setOpenSidebar(false));
        props.onClick && props.onClick();
      }}
    >
      {/* <TheIcon className="w-4 h-4 mr-1 text-blue-600" aria-hidden="true" /> */}
      {props.icon}
      <span>{props.label}</span>
    </Link>
  );
}

export default SidebarMenu;
