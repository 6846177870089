import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Input from "../../../Components/Utils/Input";

import CustomSelect from "../../../Components/Utils/CustomSelect";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  useGetGradesQuery,
  useGetDegreeModesQuery,
  useGetSubjectsQuery,
  useGetDegreeTypesQuery,
  useLazyGetDegreeTypesByCourseQuery,
  useLazyGetDegreeModesByCourseQuery,
  useLazyGetSubjectsByCourseQuery,
  useGetStatesQuery,
} from "../../../store/slices/apis/masterApi";
import { useUpdateApplicationMutation } from "../../../store/slices/apis/applicationApi";
import { toast } from "react-toastify";
import Loader from "../../../Components/Utils/Loader";
import { useSelector } from "react-redux";

// const {
//   data: subjects,
//   isLoading: isLoadingGetSubject,
//   isSuccess: isSuccessGetSubject,
//   isError: isErrorGetSubject,
// } = useGetSubjectsQuery();

export const NameCorrectionRegistrationCertificateCreateStep2 = ({
  data,
  setTab,
}) => {
  const [isLoading, setLoading] = useState(false);

  const authData = useSelector((state) => state.auth);

  const validationSchema = Yup.object().shape({
    // degree_mode_id: Yup.number().required("Please select an Degree Mode"),
    // year_of_qualify: Yup.number().typeError("Must be a valid year").min('2022', 'Minimum value  2022').max('2023','Maximum value 2023').required("Year is required"),
    // selectedRollNo: Yup.string().required("Please select Roll & No."),
    // exam_roll_no: Yup.string().required("Please enter Roll No."),
    // admitted_sem: Yup.string().required("Please select Semester"),
    // subjects: Yup.string().required("Please select Semester"),
    // hslc_year: Yup.string().required("Please enter Year of Passing"),
    // hslc_board: Yup.string().required("Please enter Board/Council/University"),
    // hslc_roll_no: Yup.string().required("Please enter Roll No."),
    // hslc_subjects: Yup.string().required("Please enter Subject(s)"),
    // hsslc_year: Yup.string().required("Please enter Year of Passing"),
    // hsslc_board: Yup.string().required("Please enter Board/Council/University"),
    // hsslc_roll_no: Yup.string().required("Please enter Roll No."),
    // hsslc_subjects: Yup.string().required("Please enter Subject(s)"),

    village: Yup.string().required("Please select village"),
    house_no: Yup.string().required("Please select House No./Street No."),
    postoffice: Yup.string().required("Please select Post Office"),
    district: Yup.string().required("Please select District"),
    state_id: Yup.string().required("State is required"),
    pincode: Yup.string().required("Please select Pin"),
    phone_number: Yup.string()
      .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Invalid mobile number")
      .required("Mobile number is required"),
    email: Yup.string().email("Invalid email address").required("Required"),
  });

  const [isSaving, setSaving] = useState(false);
  // const [applicationId, setApplicationId] = useState(0);
  const [updateApplication] = useUpdateApplicationMutation();
  const [initialValues, setInitialValues] = useState({
    // exam_roll_no: "",
    // admitted_sem: "",
    // subjects: "",
    hslc_year: "",
    hslc_board: "",
    hslc_roll_no: "",
    hslc_subjects: "",
    hsslc_year: "",
    hsslc_board: "",
    hsslc_roll_no: "",
    hsslc_subjects: "",
    bachelor_year: "",
    bachelor_board: "",
    bachelor_roll_no: "",
    bachelor_subjects: "",

    village: "",
    houseNo: "",
    postoffice: "",
    district: "",
    state_id: "",
    pincode: "",
    phone_number: "",
    email: "",
  });

  const {
    data: states = [],
    isLoading: isLoadingGetStates,
    isSuccess: isSuccessGetStates,
    isError: isErrorGetStates,
  } = useGetStatesQuery();

  const formikRef = useRef();

  const handleSubmit = async (payload) => {
    console.log("payload", payload);
    try {
      setSaving(true);
      const result = await updateApplication({
        id: data.id,
        step: 3,
        ...payload,
      }).unwrap();
      if (result.status) {
        toast("Saving Successful");
        setTab(3);
      } else {
        toast(result.message || "");
      }
    } catch (err) {
      toast(err?.data?.message || "");
      console.log("error ", err);
    } finally {
      setSaving(false);
    }
  };
  useEffect(() => {
    if (data) {
      setInitialValues({
        // exam_roll_no: data?.exam_roll_no || "",
        // admitted_sem: data?.admitted_sem || "",
        // subjects: data?.subjects || "",
        hslc_year: data?.hslc_year || "",
        hslc_board: data?.hslc_board || "",
        hslc_roll_no: data?.hslc_roll_no || "",
        hslc_subjects: data?.hslc_subjects || "",
        hsslc_year: data?.hsslc_year || "",
        hsslc_board: data?.hsslc_board || "",
        hsslc_roll_no: data?.hsslc_roll_no || "",
        hsslc_subjects: data?.hsslc_subjects || "",
        bachelor_year: data?.bachelor_year || "",
        bachelor_board: data?.bachelor_board || "",
        bachelor_roll_no: data?.bachelor_roll_no || "",
        bachelor_subjects: data?.bachelor_subjects || "",

        village: data?.village || "",
        house_no: data?.house_no || "",
        postoffice: data?.postoffice || "",
        district: data?.district || "",
        state_id: data?.state_id || "",
        pincode: data?.pincode || "",

        phone_number: data?.phone_number ?? authData.user?.user?.phone ?? "",
        email: data?.email ?? authData.user?.user?.email ?? "",

        step: 3,
      });
    }
  }, [data]);
  return (
    <>
      {isSaving && <Loader />}
      <div className='grid items-center justify-center min-h-full grid-cols-1 bg-white'>
        <div className='w-full p-10 space-y-8 rounded-lg shadow-2xl '>
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
            }) => (
              <Form className='space-y-4'>
                {/* {console.log("errors", errors)} */}

                <div className='grid grid-cols-2 gap-4'>
                  <Input
                    readOnly='true'
                    type='text'
                    name='phone_number'
                    label='Mobile No'
                    placeholder='Enter Your Mobile No'
                  />
                  <Input
                    readOnly='true'
                    name='email'
                    type='email'
                    label='Email'
                    placeholder='example@example.com'
                  />
                </div>
                <br />
                <label className='mb-1 text-sm font-semibold text-gray-700'>
                  ADDRESS
                </label>
                <div className='grid grid-cols-2 gap-4'>
                  <Input
                    name='village'
                    type='text'
                    label='Village / Town'
                    placeholder='Enter Village / Town'
                  />
                  <Input
                    name='house_no'
                    type='text'
                    label='House No./Street No.'
                    placeholder='Enter Your House No./Street No.'
                  />
                </div>

                <div className='grid grid-cols-2 gap-4'>
                  <Input
                    name='postoffice'
                    type='text'
                    label='P.O.'
                    placeholder='Enter Post Office'
                  />

                  <Input
                    type='text'
                    name='district'
                    label='District'
                    placeholder='Enter District'
                  />
                </div>

                <div className='grid grid-cols-2 gap-4'>
                  <CustomSelect
                    name='state_id'
                    options={states}
                    label='State'
                    placeholder='Select State'
                    getOptionValue={(option) => `${option}`}
                    getOptionLabel={(option) => `${option.name}`}
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      formikRef.current?.setFieldValue(
                        "state_id",
                        selectedOption?.id
                      );
                    }}
                    value={values.state_id || ""}
                  />

                  <Input
                    type='text'
                    name='pincode'
                    placeholder='Enter Your Pin'
                    label='Pin'
                  />
                </div>

                <br />

                <div className='flex justify-between'>
                  <button
                    type='button'
                    className='px-4 py-2 text-white bg-green-500 rounded'
                    onClick={() => {
                      setTab(1);
                    }}
                  >
                    Previous
                  </button>
                  <button
                    disabled={isSaving}
                    type='submit'
                    className='px-4 py-2 text-white bg-blue-500 rounded'
                  >
                    {isSaving ? "Saving..." : "Next"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default NameCorrectionRegistrationCertificateCreateStep2;
