import React, { useState } from "react";
import Model from "../Components/Utils/Model";
import { useNavigate } from "react-router-dom";
const FeeStructure = ({ onContinue, onCancel, certificate_id }) => {
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  let navigate = useNavigate();
  return (
    <>
      <Model
        style={{ marginTop: "30px", borderRadius: 5 }}
        className='mywidth'
        // className="max-w-full px-4 mx-auto my-8 sm:px-6 lg:px-8"
      >
        {/* origial certificate */}
        {certificate_id == 1 && (
          <div className='container px-4 mx-auto my-3'>
            <h1 className='mb-4 text-xl font-bold'>Instructions</h1>
            <ol className='mb-8 text-sm list-decimal list-inside'>
              <li className='mb-2'>
                Distinctly visible scanned copies of University
                Registration/Migration Card, Marksheet/Gradesheet and Admit Card
                of Final Year/ Semester examination must be uploaded in JPG/JPEG
                format.
              </li>
              <li className='mb-2'>
                In case of M.B.B.S. examination, digital distinctly visible
                copies of University Registration Card, Final Year Admit Card &
                Marksheet and Internship Completion Certificate must be uploaded
                in JPG/JPEG format.
              </li>
              <li className='mb-2'>
                In case of (Medical) P.G. Degree, (Medical) P.G. Diploma
                Examinations and D. Pharm digital distinctly visible copies of
                Admit Card, Marksheet, University Registration Card and
                Provisional Certificate issued from the Institution last
                attended must be uploaded in JPG/JPEG format.
              </li>
              <li className='mb-2'>
                FEE: For Original Certificate as shown below:
              </li>
              <div className='mb-8 overflow-x-auto'>
                <table className='table-auto '>
                  <thead className='px-4 py-2 font-semibold border'>
                    <tr>
                      <th className='px-4 py-2'>Sl. No.</th>
                      <th className='px-4 py-2'>Particulars</th>
                      <th className='px-4 py-2'>Amount (in Rs.)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='px-4 py-2 border'>I</td>
                      <td className='px-4 py-2 border'>Certificate Fee</td>
                      <td className='px-4 py-2 border'>800.00</td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 border'>I</td>
                      <td className='px-4 py-2 border'>
                        Certificate & Convocation Fee
                      </td>
                      <td className='px-4 py-2 border'>1500.00</td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 border'>II</td>
                      <td className='px-4 py-2 border'>
                        If applied after two year from the date of passing
                      </td>
                      <td className='px-4 py-2 border'>950/-</td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 border'>III</td>
                      <td className='px-4 py-2 border'>
                        If applied after three year from the date of passing
                      </td>
                      <td className='px-4 py-2 border'>1100/-</td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 border'>IV</td>
                      <td className='px-4 py-2 border'>
                        If applied after four year from the date of passing
                      </td>
                      <td className='px-4 py-2 border'>1250/-</td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 border'>V</td>
                      <td className='px-4 py-2 border'>
                        If applied after five year from the date of passing
                      </td>
                      <td className='px-4 py-2 border'>1400/-</td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 border'>VI</td>
                      <td className='px-4 py-2 border'>
                        If applied after six year from the date of passing
                      </td>
                      <td className='px-4 py-2 border'>1550/-</td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 border'>VII</td>
                      <td className='px-4 py-2 border'>
                        If applied after seven year from the date of passing
                      </td>
                      <td className='px-4 py-2 border'>1700/-</td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 border'>VIII</td>
                      <td className='px-4 py-2 border'>
                        If applied after eight year from the date of passing
                      </td>
                      <td className='px-4 py-2 border'>1850/-</td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 border'>IX</td>
                      <td className='px-4 py-2 border'>
                        If applied after nine year from the date of passing
                      </td>
                      <td className='px-4 py-2 border'>2000/-</td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 border'>X</td>
                      <td className='px-4 py-2 border'>
                        If applied after ten years or more from the date of
                        passing
                      </td>
                      <td className='px-4 py-2 border'>3000/-</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <li className='mb-2'>
                Original Certificate may be handed over to the candidate or to
                person duly authorized by the candidate whose signature is
                attested by the candidate.
              </li>
              <li className='mb-2'>
                No certificate will be issued to the Principal/ Head of the
                Department/ Institution.
              </li>
              <li className='mb-2'>
                There is provision to dispatch the certificate by Registered
                Post to the candidate concerned, if the candidate so desires.
              </li>
              <li className='mb-2'>
                FEE: For Original Certificate as shown below:
              </li>
            </ol>

            <label className='inline-flex items-center text-sm'>
              <input
                type='checkbox'
                className='w-5 h-5 text-blue-600 form-checkbox'
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className='ml-2 text-gray-700'>
                I have read and understood the instruction.
              </span>
            </label>

            <div className='flex flex-col items-center justify-center mt-4 sm:flex-row'>
              <button
                className={`w-full sm:w-auto px-4 py-2 mr-0 sm:mr-4 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-700 ${
                  isChecked ? "" : "opacity-50 cursor-not-allowed"
                }`}
                onClick={onCancel}
                disabled={!isChecked}
              >
                Continue
              </button>
              <button
                className='w-full px-4 py-2 mt-4 text-gray-700 bg-gray-200 rounded sm:w-auto sm:mt-0 hover:bg-gray-300 focus:outline-none focus:shadow-outline-gray active:bg-gray-400'
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {/* Migration certificate */}
        {certificate_id == 2 && (
          <div className='container px-4 mx-auto my-3'>
            <h1 className='mb-4 text-xl font-bold'>Instructions</h1>
            <ol className='mb-8 text-sm list-decimal list-inside'>
              <li>
                Digital distinctly visible copy of University Original
                Registration Card/Duplicate Registration Card(in case of loss of
                Original Certificate) must be uploaded in JPG/JPEG format.{" "}
                <br />
              </li>
              <li>
                {" "}
                Digital distinctly visible copy of affidavit for loss of
                original registration card must be uploaded in JPG/JPEG format.{" "}
              </li>
              <li>
                {" "}
                In case of Migration certificate, after successful online
                submission of application with payment, the hard copies of the
                downloaded form duly forwarded by the Head/ Chairperson/
                Director/ Principal of the Department/ Centre
                /lnstitute/College, along with the Original Registration
                Card/Duplicate Registration Card(in case of loss of Original
                Certificate) and an affidavit for loss of original registration
                card are required to be submitted.{" "}
              </li>
              <li>
                {" "}
                For Migration Certificate 1000/- (Rupees One Thousand) only.{" "}
              </li>
            </ol>

            <label className='inline-flex items-center text-sm'>
              <input
                type='checkbox'
                className='w-5 h-5 text-blue-600 form-checkbox'
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className='ml-2 text-gray-700'>
                I have read and understood the instruction.
              </span>
            </label>

            <div className='flex flex-col items-center justify-center mt-4 sm:flex-row'>
              <button
                className={`w-full sm:w-auto px-4 py-2 mr-0 sm:mr-4 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-700 ${
                  isChecked ? "" : "opacity-50 cursor-not-allowed"
                }`}
                onClick={onCancel}
                disabled={!isChecked}
              >
                Continue
              </button>
              <button
                className='w-full px-4 py-2 mt-4 text-gray-700 bg-gray-200 rounded sm:w-auto sm:mt-0 hover:bg-gray-300 focus:outline-none focus:shadow-outline-gray active:bg-gray-400'
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {/* Pre-Degree Certificate */}
        {certificate_id == 3 && (
          <div className='container px-4 mx-auto my-3'>
            <h1 className='mb-4 text-xl font-bold'>Instructions</h1>
            <ol className='mb-8 text-sm list-decimal list-inside'>
              <li>
                Digital distinctly visible copies of University Registration
                Card/Migration Certificate, Marksheet/Gradesheet and Admit Card
                of Final Year/ Semester examination must be uploaded in JPG/JPEG
                format.
              </li>
              <li>
                For Pre-Degree Certificate 600/- (Rupees Six hundred) only.
              </li>
              <li>
                Certificate may be handed over to the candidate or to person
                duly authorized by the candidate whose signature is attested by
                the candidate.
              </li>
              <li>
                No certificate will be issued to the Principal/ Head of the
                Department/ Institution.
              </li>
              <li>
                There is provision to dispatch the certificate by Registered
                Post to the candidate concerned, if the candidate so desires.
              </li>
            </ol>

            <label className='inline-flex items-center text-sm'>
              <input
                type='checkbox'
                className='w-5 h-5 text-blue-600 form-checkbox'
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className='ml-2 text-gray-700'>
                I have read and understood the instruction.
              </span>
            </label>

            <div className='flex flex-col items-center justify-center mt-4 sm:flex-row'>
              <button
                className={`w-full sm:w-auto px-4 py-2 mr-0 sm:mr-4 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-700 ${
                  isChecked ? "" : "opacity-50 cursor-not-allowed"
                }`}
                onClick={onCancel}
                disabled={!isChecked}
              >
                Continue
              </button>
              <button
                className='w-full px-4 py-2 mt-4 text-gray-700 bg-gray-200 rounded sm:w-auto sm:mt-0 hover:bg-gray-300 focus:outline-none focus:shadow-outline-gray active:bg-gray-400'
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {/* Provisional Certificate */}
        {certificate_id == 4 && (
          <div className='container px-4 mx-auto my-3'>
            <h1 className='mb-4 text-xl font-bold'>Instructions</h1>
            <ol className='mb-8 text-sm list-decimal list-inside'>
              <li>
                Digital distinctly visible copies of University Registration
                Card/Migration Certificate, Marksheet/Gradesheet and Admit Card
                of Final Year/Semester examination must be uploaded in JPG/JPEG
                format.
              </li>
              <li>
                In case of M.B.B.S. examination, digital distinctly visible
                copies of University Registration Card, Final Year Admit Card &
                Marksheet, and Internship Completion Certificate must be
                uploaded in JPG/JPEG format.
              </li>
              <li>
                In case of (Medical) P.G. Degree, (Medical) P.G. Diploma
                Examinations and D. Pharm digital distinctly visible copies of
                Admit Card, Marksheet, University Registration Card, and
                Provisional Certificate issued from the Institution last
                attended must be uploaded in JPG/JPEG format.
              </li>
              <li>
                For Provisional Certificate 400/- (Rupees Four hundred) only.
              </li>
              <li>
                Certificate may be handed over to the candidate or to a person
                duly authorized by the candidate whose signature is attested by
                the candidate.
              </li>
              <li>
                No certificate will be issued to the Principal/Head of the
                Department/Institution.
              </li>
              <li>
                There is provision to dispatch the certificate by Registered
                Post to the candidate concerned if the candidate so desires.
              </li>
            </ol>

            <label className='inline-flex items-center text-sm'>
              <input
                type='checkbox'
                className='w-5 h-5 text-blue-600 form-checkbox'
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className='ml-2 text-gray-700'>
                I have read and understood the instruction.
              </span>
            </label>

            <div className='flex flex-col items-center justify-center mt-4 sm:flex-row'>
              <button
                className={`w-full sm:w-auto px-4 py-2 mr-0 sm:mr-4 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-700 ${
                  isChecked ? "" : "opacity-50 cursor-not-allowed"
                }`}
                onClick={onCancel}
                disabled={!isChecked}
              >
                Continue
              </button>
              <button
                className='w-full px-4 py-2 mt-4 text-gray-700 bg-gray-200 rounded sm:w-auto sm:mt-0 hover:bg-gray-300 focus:outline-none focus:shadow-outline-gray active:bg-gray-400'
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {/* Transcript Certificate */}
        {certificate_id == 5 && (
          <div className='container px-4 mx-auto my-3'>
            <h1 className='mb-4 text-xl font-bold'>Instructions</h1>
            <ol className='mb-8 text-sm list-decimal list-inside'>
              <li>
                Digital distinctly visible copies of University Registration
                Card, Mark-sheet, and Admit Card of Final Year/Semester
                examination must be uploaded in JPG/JPEG format.
              </li>
              <li>
                In case of M.B.B.S. examination, digital distinctly visible
                copies of University Registration Card, Final Year Admit Card &
                Marksheet, and Internship Completion Certificate must be
                uploaded in JPG/JPEG format.
              </li>
              <li>
                In case of (Medical) P.G. Degree, (Medical) P.G. Diploma
                Examinations and D. Pharm digital distinctly visible copies of
                Admit Card, Marksheet, University Registration Card, and
                Provisional Certificate issued from the Institution last
                attended must be uploaded in JPG/JPEG format.
              </li>
              <li>
                In case of Transcript, all relevant documents (hard copies) are
                required to be submitted, after successful online submission
                with payment in the portal.
              </li>
              <li>
                For Transcript 1000/- (Rupees Five hundred) only (Per
                Transcript).
              </li>
            </ol>

            <label className='inline-flex items-center text-sm'>
              <input
                type='checkbox'
                className='w-5 h-5 text-blue-600 form-checkbox'
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className='ml-2 text-gray-700'>
                I have read and understood the instruction.
              </span>
            </label>

            <div className='flex flex-col items-center justify-center mt-4 sm:flex-row'>
              <button
                className={`w-full sm:w-auto px-4 py-2 mr-0 sm:mr-4 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-700 ${
                  isChecked ? "" : "opacity-50 cursor-not-allowed"
                }`}
                onClick={onCancel}
                disabled={!isChecked}
              >
                Continue
              </button>
              <button
                className='w-full px-4 py-2 mt-4 text-gray-700 bg-gray-200 rounded sm:w-auto sm:mt-0 hover:bg-gray-300 focus:outline-none focus:shadow-outline-gray active:bg-gray-400'
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {/* Duplicate Certificate */}
        {certificate_id == 6 && (
          <div className='container px-4 mx-auto my-3'>
            <h1 className='mb-4 text-xl font-bold'>Instructions</h1>
            <ol className='mb-8 text-sm list-decimal list-inside'>
              <li>
                Digital distinctly visible copies of University Registration
                Card, Mark-sheet, and Admit Card of Final Year/Semester
                examination must be uploaded in JPG/JPEG format.
              </li>
              <li>
                In case of M.B.B.S. examination, digital distinctly visible
                copies of University Registration Card, Final Year Admit Card &
                Marksheet, and Internship Completion Certificate must be
                uploaded in JPG/JPEG format.
              </li>
              <li>
                In case of (Medical) P.G. Degree, (Medical) P.G. Diploma
                Examinations, and D. Pharm digital distinctly visible copies of
                Admit Card, Marksheet, University Registration Card, Provisional
                Certificate issued from the Institution last attended must be
                uploaded in JPG/JPEG format.
              </li>
              <li>
                In case of Duplicate Original Certificate, scan copies of Police
                Report, Court Affidavit, and Newspaper Cutting must be uploaded
                in JPG/JPEG format.
              </li>
              <li>
                In case of Correction in Original Certificate, a scan copy of
                the Original Certificate must be uploaded in JPG/JPEG format.
              </li>
              <li>
                In case of Duplicate Original Certificate/Correction in the
                Original Certificate after successful online submission of the
                application with payment, the hardcopies of the downloaded form
                duly forwarded by the Head/Chairperson/Director/Principal of the
                Department/Centre/Institute/College, along with all relevant
                legal documents (hard copies) are required to be submitted.
              </li>
              <li>
                For Duplicate Original certificate/Correction in the Original
                Certificate 800/- (Rupees Eight hundred) only.
              </li>
              <li>
                No certificate will be issued to the Principal/Head of the
                Department/Institution.
              </li>
            </ol>

            <label className='inline-flex items-center text-sm'>
              <input
                type='checkbox'
                className='w-5 h-5 text-blue-600 form-checkbox'
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className='ml-2 text-gray-700'>
                I have read and understood the instruction.
              </span>
            </label>

            <div className='flex flex-col items-center justify-center mt-4 sm:flex-row'>
              <button
                className={`w-full sm:w-auto px-4 py-2 mr-0 sm:mr-4 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-700 ${
                  isChecked ? "" : "opacity-50 cursor-not-allowed"
                }`}
                onClick={onCancel}
                disabled={!isChecked}
              >
                Continue
              </button>
              <button
                className='w-full px-4 py-2 mt-4 text-gray-700 bg-gray-200 rounded sm:w-auto sm:mt-0 hover:bg-gray-300 focus:outline-none focus:shadow-outline-gray active:bg-gray-400'
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {/* Duplicate Provisional Certificate */}
        {certificate_id == 7 && (
          <div className='container px-4 mx-auto my-3'>
            <h1 className='mb-4 text-xl font-bold'>Instructions</h1>
            <ol className='mb-8 text-sm list-decimal list-inside'>
              <li>
                Digital distinctly visible copies of University Registration
                Card/Migration Certificate, Marksheet/Gradesheet, and Admit Card
                of Final Year/Semester examination must be uploaded in JPG/JPEG
                format.
              </li>
              <li>
                In case of M.B.B.S. examination, digital distinctly visible
                copies of University Registration Card, Final Year Admit Card &
                Marksheet, and Internship Completion Certificate must be
                uploaded in JPG/JPEG format.
              </li>
              <li>
                In case of (Medical) P.G. Degree, (Medical) P.G. Diploma
                Examinations, and D. Pharm digital distinctly visible copies of
                Admit Card, Marksheet, University Registration Card, and
                Provisional Certificate issued from the Institution last
                attended must be uploaded in JPG/JPEG format.
              </li>
              <li>
                In case of Duplicate Provisional Certificate, scan copies of
                Police Report, Court Affidavit, and Newspaper Cutting must be
                uploaded in JPG/JPEG format.
              </li>
              <li>
                In case of Duplicate Provisional Certificate after successful
                online submission of the application with payment, the
                hardcopies of the downloaded form duly forwarded by the
                Head/Chairperson/Director/Principal of the
                Department/Centre/Institute/College, along with all relevant
                legal documents (hard copies) are required to be submitted.
              </li>
              <li>
                For Duplicate Provisional Certificate 800/- (Rupees Eight
                hundred) only.
              </li>
              <li>
                No certificate will be issued to the Principal/Head of the
                Department/Institution.
              </li>
              <li>
                There is provision to dispatch the certificate by Registered
                Post to the candidate concerned if the candidate so desires.
              </li>
            </ol>

            <label className='inline-flex items-center text-sm'>
              <input
                type='checkbox'
                className='w-5 h-5 text-blue-600 form-checkbox'
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className='ml-2 text-gray-700'>
                I have read and understood the instruction.
              </span>
            </label>

            <div className='flex flex-col items-center justify-center mt-4 sm:flex-row'>
              <button
                className={`w-full sm:w-auto px-4 py-2 mr-0 sm:mr-4 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-700 ${
                  isChecked ? "" : "opacity-50 cursor-not-allowed"
                }`}
                onClick={onCancel}
                disabled={!isChecked}
              >
                Continue
              </button>
              <button
                className='w-full px-4 py-2 mt-4 text-gray-700 bg-gray-200 rounded sm:w-auto sm:mt-0 hover:bg-gray-300 focus:outline-none focus:shadow-outline-gray active:bg-gray-400'
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {/* Duplicate Migration Certificate */}
        {certificate_id == 8 && (
          <div className='container px-4 mx-auto my-3'>
            <h1 className='mb-4 text-xl font-bold'>Instructions</h1>
            <ol className='mb-8 text-sm list-decimal list-inside'>
              <li>
                Digital distinctly visible copy of Registration card/Migration
                Certificate of Dibrugarh University must be uploaded in JPG/JPEG
                format.
              </li>
              <li>
                Digital distinctly visible copy of an affidavit sworn before
                First Class Magistrate must be uploaded in JPG/JPEG format.
              </li>
              <li>
                Digital distinctly visible copy of certificate extract from the
                Police General Diary containing entry of the information lodged
                (with the seal of the Police Officer) must be uploaded in
                JPG/JPEG format.
              </li>
              <li>
                In case of Duplicate Migration certificate, all relevant legal
                documents (hard copies) are required to be submitted, after
                successful online submission with payment in the portal.
              </li>
              <li>
                For Duplicate Migration Certificate 1000/- (Rupees One Thousand)
                only.
              </li>
            </ol>

            <label className='inline-flex items-center text-sm'>
              <input
                type='checkbox'
                className='w-5 h-5 text-blue-600 form-checkbox'
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className='ml-2 text-gray-700'>
                I have read and understood the instruction.
              </span>
            </label>

            <div className='flex flex-col items-center justify-center mt-4 sm:flex-row'>
              <button
                className={`w-full sm:w-auto px-4 py-2 mr-0 sm:mr-4 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-700 ${
                  isChecked ? "" : "opacity-50 cursor-not-allowed"
                }`}
                onClick={onCancel}
                disabled={!isChecked}
              >
                Continue
              </button>
              <button
                className='w-full px-4 py-2 mt-4 text-gray-700 bg-gray-200 rounded sm:w-auto sm:mt-0 hover:bg-gray-300 focus:outline-none focus:shadow-outline-gray active:bg-gray-400'
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {/* Surname Change */}
        {certificate_id == 9 && (
          <div className='container px-4 mx-auto my-3'>
            <h1 className='mb-4 text-xl font-bold'>Instructions</h1>
            <ol className='mb-8 text-sm list-decimal list-inside'>
              <li>
                Digital distinctly visible copy of Registration Card, Marriage
                certificate, Self-declaration Court Affidavit duly notarized in
                original, and Husband declaration Court Affidavit duly notarized
                in original must be uploaded in JPG/JPEG format.
              </li>
              <li>
                In case of surname change, all relevant legal documents (hard
                copies) are required to be submitted, after successful online
                submission with payment in the portal.
              </li>
              <li>For Surname Change 500/- (Rupees Five hundred) only.</li>
            </ol>

            <label className='inline-flex items-center text-sm'>
              <input
                type='checkbox'
                className='w-5 h-5 text-blue-600 form-checkbox'
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className='ml-2 text-gray-700'>
                I have read and understood the instruction.
              </span>
            </label>

            <div className='flex flex-col items-center justify-center mt-4 sm:flex-row'>
              <button
                className={`w-full sm:w-auto px-4 py-2 mr-0 sm:mr-4 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-700 ${
                  isChecked ? "" : "opacity-50 cursor-not-allowed"
                }`}
                onClick={onCancel}
                disabled={!isChecked}
              >
                Continue
              </button>
              <button
                className='w-full px-4 py-2 mt-4 text-gray-700 bg-gray-200 rounded sm:w-auto sm:mt-0 hover:bg-gray-300 focus:outline-none focus:shadow-outline-gray active:bg-gray-400'
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {/* Registration Card */}
        {certificate_id == 10 && (
          <div className='container px-4 mx-auto my-3'>
            <h1 className='mb-4 text-xl font-bold'>Instructions</h1>
            <ol className='mb-8 text-sm list-decimal list-inside'>
              <li>
                Digital distinctly visible copy of final Marksheet/ Gradesheet
                of the qualifying examination must be uploaded in JPG/JPEG
                format.
              </li>
              <li>
                In case of Registration Card after successful online submission
                of application with payment, the hardcopies of the downloaded
                from duly forwarded by the Head/ Chairperson/ Director/
                Principal of the Department/Centre/lnstitute/College, along with
                the self-attested photocopies of certificates of examination
                passed are required to submit.
              </li>
              <li>
                {" "}
                For Registration Card 350/- (Rupees Three hundred fifty) only.
              </li>
            </ol>

            <label className='inline-flex items-center text-sm'>
              <input
                type='checkbox'
                className='w-5 h-5 text-blue-600 form-checkbox'
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className='ml-2 text-gray-700'>
                I have read and understood the instruction.
              </span>
            </label>

            <div className='flex flex-col items-center justify-center mt-4 sm:flex-row'>
              <button
                className={`w-full sm:w-auto px-4 py-2 mr-0 sm:mr-4 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-700 ${
                  isChecked ? "" : "opacity-50 cursor-not-allowed"
                }`}
                onClick={onCancel}
                disabled={!isChecked}
              >
                Continue
              </button>
              <button
                className='w-full px-4 py-2 mt-4 text-gray-700 bg-gray-200 rounded sm:w-auto sm:mt-0 hover:bg-gray-300 focus:outline-none focus:shadow-outline-gray active:bg-gray-400'
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {/* Duplicate Registration Card */}
        {certificate_id == 11 && (
          <div className='container px-4 mx-auto my-3'>
            <h1 className='mb-4 text-xl font-bold'>Instructions</h1>
            <ol className='mb-8 text-sm list-decimal list-inside'>
              <li>
                Digital distinctly visible copy of Admitcard mentioning the
                Registration Number must be uploaded in JPG/JPEG format.
              </li>
              <li>
                In case of Duplicate Registration Card, all relevant legal
                documents (hard copies) are required to be submitted, after
                successful online submission with payment in the portal.
              </li>
              <li>
                For Duplicate Registration Card 500/- (Rupees Five hundred)
                only.
              </li>
            </ol>

            <label className='inline-flex items-center text-sm'>
              <input
                type='checkbox'
                className='w-5 h-5 text-blue-600 form-checkbox'
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className='ml-2 text-gray-700'>
                I have read and understood the instruction.
              </span>
            </label>

            <div className='flex flex-col items-center justify-center mt-4 sm:flex-row'>
              <button
                className={`w-full sm:w-auto px-4 py-2 mr-0 sm:mr-4 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-700 ${
                  isChecked ? "" : "opacity-50 cursor-not-allowed"
                }`}
                onClick={onCancel}
                disabled={!isChecked}
              >
                Continue
              </button>
              <button
                className='w-full px-4 py-2 mt-4 text-gray-700 bg-gray-200 rounded sm:w-auto sm:mt-0 hover:bg-gray-300 focus:outline-none focus:shadow-outline-gray active:bg-gray-400'
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {/* Name Correction Registration Card */}
        {certificate_id == 12 && (
          <div className='container px-4 mx-auto my-3'>
            <h1 className='mb-4 text-xl font-bold'>Instructions</h1>
            <ol className='mb-8 text-sm list-decimal list-inside'>
              <li>
                Digital distinctly visible copy of Original Registration Card of
                D.U., Admit card of 10th Standard and Court Affidavit duly
                notarized in original must be uploaded in JPG/JPEG format.
              </li>
              <li>
                In case of Name Correction in Registration Card, all relevant
                legal documents (hard copies) are required to be submitted,
                after successful online submission with payment in the portal.
              </li>
              <li>
                For Name Correction in Registration Card 350/- (Rupees Three
                hundred fifty) only
              </li>
            </ol>

            <label className='inline-flex items-center text-sm'>
              <input
                type='checkbox'
                className='w-5 h-5 text-blue-600 form-checkbox'
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className='ml-2 text-gray-700'>
                I have read and understood the instruction.
              </span>
            </label>

            <div className='flex flex-col items-center justify-center mt-4 sm:flex-row'>
              <button
                className={`w-full sm:w-auto px-4 py-2 mr-0 sm:mr-4 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-700 ${
                  isChecked ? "" : "opacity-50 cursor-not-allowed"
                }`}
                onClick={onCancel}
                disabled={!isChecked}
              >
                Continue
              </button>
              <button
                className='w-full px-4 py-2 mt-4 text-gray-700 bg-gray-200 rounded sm:w-auto sm:mt-0 hover:bg-gray-300 focus:outline-none focus:shadow-outline-gray active:bg-gray-400'
                onClick={() => {
                  navigate("/dashboard");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Model>
    </>
  );
};

export default FeeStructure;
