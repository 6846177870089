import React, { useState } from "react";
import styles from "./../../styles/Stepper.module.css";
import { HiCheckCircle } from "react-icons/hi";
import { FiCircle } from "react-icons/fi";
import { ApplicationStatus } from "../../Utils/Status";
import { Link } from "react-router-dom";
const Stepper = ({ data, status }) => {
  // console.log("Stepper status", data.status);
  const activeStyle = { width: 40, height: 40, color: "#6366F1" };
  const inActiveStyle = { width: 40, height: 40, color: "#cccccc" };
  return (
    <div>
      <h3 className='m-3 text-lg font-bold text-left'>
        {data?.certificate_name || ""}
        <p>
          {" "}
          Status:
          {(data?.admin_status === 0 ||
            data?.admin_status === 1 ||
            data?.admin_status === 2 ||
            data?.admin_status === 3) &&
            "Pending"}
          {data?.admin_status == -1 && "Rejected"}
          {data?.admin_status == 4 && "Issued"}
          {data?.notesheet_status == "Ready" ? " - Ready to Collect" : ""}
          {data?.admin_status == 5 ? " - Ready to Collect" : ""}
          {data?.admin_status == 6 ? " - Delivered" : ""}
        </p>
        {data?.admin_status == -1 && (
          <>
            {data?.rejected_remarks}
            <br />
          </>
        )}
        {/* link to reupload */}
        {data?.admin_status == -1 && data?.allow_re_upload == 1 && (
          <Link to={`/document-reupload/${data?.id}`} className='text-red-500'>
            Click here to reupload documents
          </Link>
        )}
      </h3>
    </div>
  );
};

export default Stepper;
