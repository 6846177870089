import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../../../Components/Utils/CustomSelect.js";
import { useDispatch } from "react-redux";
import Input from "../../../Components/Utils/Input.js";
import {
  useGetCoursesQuery,
  useGetCollegesQuery,
  useGetCertificateQuery,
  useLazyGetDegreeTypesByCourseQuery,
  useLazyGetDegreeModesByCourseQuery,
  useGetGradesQuery,
} from "../../../store/slices/apis/masterApi.js";
import AssameseKeyboard from "../../../Components/Utils/AssameseKeyboard.jsx";
import AssameseInput from "../../../Components/Utils/AssameseInput.js";
import {
  useAddApplicationMutation,
  useGetApplicationQuery,
  useUpdateApplicationMutation,
  useLazyGetDataByRegNoQuery,
} from "../../../store/slices/apis/applicationApi.js";
import { toast } from "react-toastify";
import Loader from "../../../Components/Utils/Loader.jsx";
import useDebounce from "../../../hooks/useDebounce.js";
import axios from "axios";
import SearchInput from "../../../Components/Utils/SearchInput.js";
import SearchableDropdown from "../../../Components/Utils/SearchableDropdown.js";
export const SurnameChangeCertificateCreateStep1 = ({
  data,
  certificate_id,
  setTab,
}) => {
  // console.log("CertificateCreateStep1", data?.step, data?.step > 1);

  const [isLoading, setLoading] = useState(false);
  const [autoPopulate, setAutoPopulate] = useState(false);
  const [regNoSuggestions, setRegNoSuggestions] = useState([]);
  const handleUpdateSuggestions = (suggestions) => {
    setRegNoSuggestions(suggestions);
  };
  const [query, setQuery] = useState(null);
  const debounceQuery = useDebounce(query, 1000);
  const formikRef = useRef();
  const [shouldApplyOnFocus, setShouldApplyOnFocus] = useState(false);
  const [
    getDataByRegNo,
    {
      data: regData,
      isFetching: isFetchingGetRegData,
      isLoading: isLoadingGetRegData,
      isSuccess: isSuccessGetRegData,
      isError: isErrorGetRegData,
    },
  ] = useLazyGetDataByRegNoQuery();

  useEffect(() => {
    console.log(debounceQuery);

    // if (debounceQuery && autoPopulate == 1) {
    if (autoPopulate == 1) {
      (async () => {
        try {
          getDataByRegNo({
            debounceQuery,
            course_id: formikRef?.current?.values?.course_id,
          });
        } catch (err) {
        } finally {
        }
      })();
    }
  }, [debounceQuery]);

  useEffect(() => {
    if (isSuccessGetRegData) {
      if (formikRef.current) {
        if (regData) {
          const previousValues = formikRef.current.values;
          const newValues = {
            ...previousValues,
            auto_populated: 1,
            name: regData.name || "",
            father_name: regData.father_name || "",
            exam_roll_no: regData.exam_roll_no || "",
            college_id: colleges.find((c) => c.code == regData.college_code)
              ?.id,
            degree_type_id: degreeTypes.find(
              (dc) => dc.name == regData.degree_type_name
            )?.id,
            degree_mode_id: degreeModes.find(
              (dm) => dm.name == regData.degree_mode_name
            )?.id,
            grade_id: grades.find((g) => g.name == regData.grade_name)?.id,
            // registration_no: regData.reg_no || "",
          };
          formikRef.current.setValues(newValues);
          console.log(degreeTypes);
        } else {
          toast.error(
            "Data not found. Please enter a valid registration number"
          );
          formikRef.current.setFieldValue("auto_populated", 0);
          formikRef.current.setFieldValue("name", "");
          formikRef.current.setFieldValue("father_name", "");
          formikRef.current.setFieldValue("exam_roll_no", "");
          formikRef.current.setFieldValue("college_id", null);
          formikRef.current.setFieldValue("last_college_name", null);
          formikRef.current.setFieldValue("degree_type_id", null);
          formikRef.current.setFieldValue("degree_mode_id", null);
          formikRef.current.setFieldValue("grade_id", null);
        }
      }
    }
  }, [regData, isLoadingGetRegData, isSuccessGetRegData, isErrorGetRegData]);

  const [isSaving, setSaving] = useState(false);
  const [currentInput, setCurrentInput] = useState("");
  const [inputName, setInputName] = useState("");
  const [addApplication] = useAddApplicationMutation();
  const [updateApplication] = useUpdateApplicationMutation();
  const [isKeyboardVisible, setKeyboardVisibility] = useState(false);
  const {
    data: courses = [],
    isLoading: isLoadingGetCourses,
    isSuccess: isSuccessGetCourses,
    isError: isErrorGetCourses,
  } = useGetCoursesQuery();
  const {
    data: colleges = [],
    isLoading: isLoadingGetColleges,
    isSuccess: isSuccessGetColleges,
    isError: isErrorGetColleges,
  } = useGetCollegesQuery();

  const {
    data: grades = [],
    isLoading: isLoadingGetGrades,
    isSuccess: isSuccessGetGrades,
    isError: isErrorGetGrades,
  } = useGetGradesQuery();

  const [
    getDegreeTypesByCourse,
    {
      data: degreeTypes = [],
      isFetching: isFetchingGetDegreeTypes,
      isLoading: isLoadingGetDegreeTypes,
      isSuccess: isSuccessGetDegreeTypes,
      isError: isErrorGetDegreeTypes,
    },
  ] = useLazyGetDegreeTypesByCourseQuery();

  const [
    getDegreeModesByCourse,
    {
      data: degreeModes = [],
      isFetching: isFetchingGetDegreeModes,
      isLoading: isLoadingGetDegreeModes,
      isSuccess: isSuccessGetDegreeModes,
      isError: isErrorGetDegreeModes,
    },
  ] = useLazyGetDegreeModesByCourseQuery();

  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Candidate Name is required"),
    maiden_name: Yup.string().required("This field is required"),
    father_name: Yup.string().required("This field is required"),
    husband_name: Yup.string().required("This field is required"),
    marriage_date: Yup.string().required("This field is required"),
    marriage_place: Yup.string().required("This field is required"),
    legal_name: Yup.string().required("This field is required"),
  });
  const [initialValues, setInitialValues] = useState({
    auto_populated: 0,
    assamese_declaration: false,
    terms_declaration: false,
    stepone_declaration: false,
    certificate_id: certificate_id,
    course_id: null,
    final_sem: "",

    registration_no: "",
    name: "",
    maiden_name: "",

    father_name: "",
    husband_name: "",
    marriage_date: "",
    marriage_place: "",
    legal_name: "",
    exam_roll_no: "",
    college_id: null,
    last_college_name: null,
    degree_type_id: null,
    degree_mode_id: null,
    grade_id: null,
    phd_notification_no: "",
    assamese_name: "",
    // re_assamese_name: "",
    step: 2,
  });

  useEffect(() => {
    data &&
      setInitialValues({
        auto_populated: data?.auto_populated || 0,
        course_id: data?.course_id || null,
        final_sem: data?.final_sem ?? "",

        registration_no: data?.registration_no ?? "",
        name: data?.name ?? "",
        maiden_name: data?.maiden_name ?? "",
        father_name: data?.father_name ?? "",
        husband_name: data?.husband_name ?? "",
        marriage_date: data?.marriage_date ?? "",
        marriage_place: data?.marriage_place ?? "",
        legal_name: data?.legal_name ?? "",
        exam_roll_no: data?.exam_roll_no ?? "",
        college_id: data?.college_id || null,
        last_college_name: data?.last_college_name || null,
        degree_type_id: data?.degree_type_id || null,
        degree_mode_id: data?.degree_mode_id || null,
        grade_id: data?.grade_id || null,
        phd_notification_no: data?.phd_notification_no || "",
        assamese_name: data?.assamese_name ?? "",
        // re_assamese_name: data?.assamese_name ?? "",
        assamese_declaration: data?.assamese_declaration || false,
        stepone_declaration: data?.stepone_declaration || false,
      });

    // data?.registration_no && setQuery(data?.registration_no);

    data?.auto_populated && setAutoPopulate(data?.auto_populated);
    data?.course_id && getDegreeTypesByCourse(data?.course_id);
    data?.course_id && getDegreeModesByCourse(data?.course_id);
  }, [dispatch, data]);

  const handleSubmit = async (payload) => {
    setSaving(true);
    if (!data) {
      try {
        const result = await addApplication(payload).unwrap();
        if (result.status) {
          setTab(2);
          toast("Updated Successfully");
        } else {
          toast(result.message || "");
        }
      } catch (err) {
        toast(err?.data?.message || "");
        console.log("error ", err);
      } finally {
        setSaving(false);
      }
    } else {
      try {
        const result = await updateApplication({
          ...payload,
          id: data.id,
          step: 2,
        }).unwrap();
        if (result.status) {
          setTab(2);
          toast("Updated Successfully");
        } else {
        }
      } catch (err) {
        toast(err?.data?.message || "");
        console.log("error ", err);
      } finally {
        setSaving(false);
      }
    }
  };

  useEffect(() => {
    setShouldApplyOnFocus(true);
  }, []);
  return (
    <>
      {isSaving && <Loader />}
      <div className='grid items-center justify-center min-h-full grid-cols-1 bg-white'>
        <div className='w-full p-5 space-y-8 rounded-lg shadow-2xl '>
          <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              setFieldValue,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
            }) => (
              <Form>
                <div className='grid gap-3 md:grid-cols-3 sm:grid-cols-1'>
                  <div>
                    <Input
                      name='name'
                      label='Name of the Candidate'
                      placeholder='Enter Name of the Candidate'
                    />
                  </div>
                  <div>
                    <Input
                      name='maiden_name'
                      label='Maiden Name '
                      placeholder='Enter Maiden Name'
                    />
                  </div>
                  <div>
                    <Input
                      name='father_name'
                      label="Father's Name"
                      placeholder="Enter Father' Name"
                    />
                  </div>
                  <div>
                    <Input
                      name='husband_name'
                      label="Husband's Name"
                      placeholder="Enter Husband' Name"
                    />
                  </div>
                  <div>
                    <Input
                      type='date'
                      name='marriage_date'
                      label='Date of Marriage'
                      placeholder='Enter Date of Marriage'
                    />
                  </div>
                  <div>
                    <Input
                      type='text'
                      name='marriage_place'
                      label='Place of Marriage'
                      placeholder='Enter Place of Marriage'
                    />
                  </div>
                  <div>
                    <Input
                      type='text'
                      name='legal_name'
                      label='Legal name after marriage'
                      placeholder='Enter Legal name after marriage'
                    />
                  </div>

                  <div className='md:col-span-3'>
                    <input
                      type='checkbox'
                      id='declarationCheckbox'
                      name='stepone_declaration'
                      checked={values?.stepone_declaration}
                      className='text-green-600 border-gray-300 rounded shadow-sm focus:border-green-600 focus:ring focus:ring-green-200 focus:ring-opacity-50'
                      onChange={(e) => {
                        setFieldValue("stepone_declaration", e.target.checked);
                      }}
                    />
                    <label
                      htmlFor='declarationCheckbox'
                      className='ml-2 text-sm text-gray-700'
                    >
                      I declare that the information provided in this form is
                      true and correct to the best of my knowledge and belief.
                    </label>
                  </div>
                  <hr />
                  <div className='flex justify-end md:col-span-3'>
                    <button
                      disabled={isSaving || !values.stepone_declaration}
                      className={`${
                        values.stepone_declaration
                          ? "bg-blue-500"
                          : "bg-blue-300"
                      } text-white py-2 px-4 rounded`}
                      type='submit'
                      // onClick={handleSubmit}
                    >
                      {isSaving ? "Saving..." : "Next"}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default SurnameChangeCertificateCreateStep1;

// const handleScreenClick = (e) => {
//   e.preventDefault();
//   if (isAssameseKeyboardVisible) {
//     setAssameseKeyboardVisibility(false);
//   }
// };
// const targetDivRef = useRef();
// useEffect(() => {
//   document.addEventListener("click", handleClickOutside);
//   return () => {
//     document.removeEventListener("click", handleClickOutside);
//   };
// }, []);
// const handleClickOutside = (event) => {
//   // console.log('targetDivRef',targetDivRef.current)
//   // console.log('event.target',event.target)
//   // if (targetDivRef.current && !targetDivRef.current.contains(event.target)) {
//   //   setAssameseKeyboardVisibility(false);
//   // }
// };
